/* eslint-disable no-console */
import React, {
  ChangeEvent,
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

import { Lista } from './Lista';
import { InputSelectProps, SelectType } from './protocols';
import { InputContainer } from './styles';

export const InputSelect: React.FC<InputSelectProps> = ({
  label = '',
  ListaWidth = '100%',
  listaHeight = 'none',
  placeholder = '',
  name,
  register,
  isError,
  control,
  changeSelected,
  options,
  isDisabled = false,
  autoComplete = 'new-off',
  setValue,
  ...rest
}) => {
  /**
   * inputRef
   * Ref do input utilizado para setar a label da opção selecionada no input
   */
  const inputRef = useRef<HTMLInputElement | null>(null);

  /**
   * selected, setSelected
   * Opção selecionada da lista
   */
  const [selected, setSelected] = useState<SelectType | null>(null);

  /**
   * isOpen
   * Renderiza ou não a lista
   */
  const [isOpen, setIsOpen] = useState(false);

  /**
   * filter
   * Filtro passado para a api
   */
  const [filter, setFilter] = useState('');

  const handleInputClick = useCallback(
    (event) => {
      setIsOpen(true);
      const inputValue = event.target.value.toLowerCase();

      if (options.length === 0) return;

      const selectedOption = options.find(
        (opt) => opt.label.toLowerCase() === inputValue,
      );

      if (selectedOption) {
        register(name, {
          value: { value: selectedOption.value, label: selectedOption.label },
        });
      }
    },
    [setIsOpen, options, register, name],
  );

  useEffect(() => {
    // Adiciona o valor dentro do estado selected para exibir option selecionado
    setTimeout(() => {
      if (inputRef.current) {
        if (inputRef.current.placeholder !== placeholder) {
          const current = inputRef?.current;
          const optionSelected = options.find(
            (option) => option.label === current.placeholder,
          );
          if (optionSelected) {
            setSelected(optionSelected);
          }
        }
      }
    }, 1000);
  }, []);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.toUpperCase();

      setFilter(value);
      setSelected(null);
    },
    [],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isOpen && event.key === 'Tab') setIsOpen(false);
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isOpen]);

  return (
    <InputContainer>
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}

        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field }: { field: { value: SelectType } }) => {
            const inputValue = (field.value?.label || selected?.label) ?? '';
            // Quebra o INPUT - Verificar com o Dev a utilização
            // setTimeout(() => {
            //   if (inputRef.current) {
            //     if (field.value?.label) {
            //       inputRef.current.value = field.value?.label;
            //     }
            //   }
            // }, 0);

            /**
             * Condicinal que verifica se o valor do input esta vazio,
             * caso estiver sera atribuido ou o valor do placeholder
             * ou da propria pesquisa no placeholder
             */

            if (inputRef.current?.value === '') {
              const value = inputValue === '' ? placeholder : inputValue;
              inputRef.current.placeholder = value;
            }

            if (
              inputRef.current?.placeholder &&
              field.value?.label === undefined
            ) {
              inputRef.current.placeholder = placeholder;
              inputRef.current.value = filter;
            }

            return (
              <>
                <label className="select-container">
                  <input
                    {...register(name)}
                    disabled={isDisabled}
                    type="text"
                    className={
                      isError ? 'form-control is-invalid' : 'form-control'
                    }
                    placeholder={placeholder || inputValue}
                    onChange={handleInputChange}
                    autoComplete="off"
                    onClick={handleInputClick}
                    onBlur={() => {
                      if (field.value?.label) {
                        const optionSelected = options.find(
                          (option) => option.label === field.value?.label,
                        );
                        if (optionSelected) {
                          changeSelected(optionSelected);
                        }
                      }
                    }}
                    ref={inputRef}
                    onFocus={() => setIsOpen(true)}
                    {...rest}
                  />
                  <div className="drop-indicator">
                    <span role="img" aria-label="open">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        role="presentation"
                      >
                        <path
                          d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                          fill="currentColor"
                          fillRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                </label>
                {isOpen && (
                  <Lista
                    listaWidth={ListaWidth}
                    listaHeight={listaHeight}
                    options={options}
                    inputRef={inputRef}
                    setIsOpen={setIsOpen}
                    filter={filter}
                    setFilter={setFilter}
                    selected={selected}
                    setSelected={setSelected}
                    changeSelected={changeSelected}
                  />
                )}
              </>
            );
          }}
        />
      </Form.Group>
    </InputContainer>
  );
};
